import React from 'react'

export const ResearcherSVG = () => {
  return (
    <>
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.2129 42.6953C32.497 42.6953 41.6445 33.5478 41.6445 22.2637C41.6445 10.9796 32.497 1.83203 21.2129 1.83203C9.92881 1.83203 0.78125 10.9796 0.78125 22.2637C0.78125 33.5478 9.92881 42.6953 21.2129 42.6953Z"
          fill="#60B7FF"
        />
        <path
          d="M21.2123 40.1565C31.0943 40.1565 39.1053 32.1456 39.1053 22.2636C39.1053 12.3816 31.0943 4.37061 21.2123 4.37061C11.3303 4.37061 3.31934 12.3816 3.31934 22.2636C3.31934 32.1456 11.3303 40.1565 21.2123 40.1565Z"
          fill="#8AC9FE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.2129 22.2639H41.6445C41.6445 29.8258 37.5361 36.4275 31.4301 39.9605L21.2129 22.2639Z"
          fill="#FD4755"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.2129 22.2639H39.1059C39.1059 28.8861 35.508 34.6678 30.1607 37.7617L21.2129 22.2639Z"
          fill="#FE646F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.6445 22.2639H21.2129V1.83221C32.4969 1.83221 41.6445 10.9797 41.6445 22.2639Z"
          fill="#FFD064"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M39.1059 22.2639H21.2129V4.37091C31.0949 4.37091 39.1059 12.3818 39.1059 22.2639Z"
          fill="#FFE177"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M67.4287 10.9594C67.0143 10.9594 66.6169 10.7947 66.3239 10.5017C66.0308 10.2087 65.8662 9.81127 65.8662 9.39687C65.8662 8.98247 66.0308 8.58504 66.3239 8.29201C66.6169 7.99899 67.0143 7.83437 67.4287 7.83437H82.5426C82.957 7.83437 83.3544 7.99899 83.6474 8.29201C83.9405 8.58504 84.1051 8.98247 84.1051 9.39687C84.1051 9.81127 83.9405 10.2087 83.6474 10.5017C83.3544 10.7947 82.957 10.9594 82.5426 10.9594H67.4287ZM67.4287 4.41757C67.0143 4.41757 66.6169 4.25295 66.3239 3.95993C66.0308 3.6669 65.8662 3.26947 65.8662 2.85507C65.8662 2.44067 66.0308 2.04324 66.3239 1.75022C66.6169 1.45719 67.0143 1.29257 67.4287 1.29257H97.6562C98.0706 1.29257 98.4681 1.45719 98.7611 1.75022C99.0541 2.04324 99.2187 2.44067 99.2187 2.85507C99.2187 3.26947 99.0541 3.6669 98.7611 3.95993C98.4681 4.25295 98.0706 4.41757 97.6562 4.41757H67.4287Z"
          fill="#A79BA7"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M53.5189 1.29257H58.7885C59.9994 1.29257 60.9871 2.28027 60.9871 3.4912V8.76073C60.9871 9.97167 59.9994 10.9594 58.7885 10.9594H53.5189C52.308 10.9594 51.3203 9.97167 51.3203 8.76073V3.4912C51.3203 2.28027 52.308 1.29257 53.5189 1.29257Z"
          fill="#8AC9FE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M67.4287 27.0973C67.0143 27.0973 66.6169 26.9326 66.3239 26.6396C66.0308 26.3466 65.8662 25.9492 65.8662 25.5348C65.8662 25.1204 66.0308 24.7229 66.3239 24.4299C66.6169 24.1369 67.0143 23.9723 67.4287 23.9723H82.5426C82.957 23.9723 83.3544 24.1369 83.6474 24.4299C83.9405 24.7229 84.1051 25.1204 84.1051 25.5348C84.1051 25.9492 83.9405 26.3466 83.6474 26.6396C83.3544 26.9326 82.957 27.0973 82.5426 27.0973H67.4287ZM67.4287 20.5553C67.0143 20.5553 66.6169 20.3906 66.3239 20.0976C66.0308 19.8046 65.8662 19.4072 65.8662 18.9928C65.8662 18.5784 66.0308 18.1809 66.3239 17.8879C66.6169 17.5949 67.0143 17.4303 67.4287 17.4303H97.6562C98.0706 17.4303 98.4681 17.5949 98.7611 17.8879C99.0541 18.1809 99.2187 18.5784 99.2187 18.9928C99.2187 19.4072 99.0541 19.8046 98.7611 20.0976C98.4681 20.3906 98.0706 20.5553 97.6562 20.5553H67.4287Z"
          fill="#A79BA7"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M53.5189 17.4303H58.7885C59.9994 17.4303 60.9871 18.418 60.9871 19.6289V24.8984C60.9871 26.1094 59.9994 27.0971 58.7885 27.0971H53.5189C52.308 27.0971 51.3203 26.1094 51.3203 24.8984V19.6289C51.3203 18.418 52.308 17.4303 53.5189 17.4303Z"
          fill="#FFE177"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M67.4287 43.235C67.0143 43.235 66.6169 43.0703 66.3239 42.7773C66.0308 42.4843 65.8662 42.0869 65.8662 41.6725C65.8662 41.2581 66.0308 40.8606 66.3239 40.5676C66.6169 40.2746 67.0143 40.11 67.4287 40.11H82.5426C82.957 40.11 83.3544 40.2746 83.6474 40.5676C83.9405 40.8606 84.1051 41.2581 84.1051 41.6725C84.1051 42.0869 83.9405 42.4843 83.6474 42.7773C83.3544 43.0703 82.957 43.235 82.5426 43.235H67.4287ZM67.4287 36.693C67.0143 36.693 66.6169 36.5283 66.3239 36.2353C66.0308 35.9423 65.8662 35.5449 65.8662 35.1305C65.8662 34.7161 66.0308 34.3186 66.3239 34.0256C66.6169 33.7326 67.0143 33.568 67.4287 33.568H97.6562C98.0706 33.568 98.4681 33.7326 98.7611 34.0256C99.0541 34.3186 99.2187 34.7161 99.2187 35.1305C99.2187 35.5449 99.0541 35.9423 98.7611 36.2353C98.4681 36.5283 98.0706 36.693 97.6562 36.693H67.4287Z"
          fill="#A79BA7"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M53.5189 33.568H58.7885C59.9994 33.568 60.9871 34.5557 60.9871 35.7666V41.0361C60.9871 42.2471 59.9994 43.2348 58.7885 43.2348H53.5189C52.308 43.2348 51.3203 42.2471 51.3203 41.0361V35.7666C51.3203 34.5557 52.308 33.568 53.5189 33.568Z"
          fill="#FE646F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M39.6504 68.307H46.1385C47.2143 68.307 48.0916 69.1844 48.0916 70.2602V96.7545C48.0916 97.8303 47.2143 98.7076 46.1385 98.7076H39.6504C38.5746 98.7076 37.6973 97.8303 37.6973 96.7545V70.2602C37.6973 69.1844 38.5748 68.307 39.6504 68.307Z"
          fill="#FFE177"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M46.1385 68.307C47.2143 68.307 48.0916 69.1844 48.0916 70.2602V96.7545C48.0916 97.8303 47.2143 98.7076 46.1385 98.7076H39.6504C38.5746 98.7076 37.6973 97.8303 37.6973 96.7545V96.7543H44.1854C45.2611 96.7543 46.1385 95.877 46.1385 94.8012V68.307Z"
          fill="#FFD064"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M58.5146 68.3072H65.0027C66.0785 68.3072 66.9559 69.1846 66.9559 70.2603V96.7547C66.9559 97.8305 66.0785 98.7078 65.0027 98.7078H58.5146C57.4389 98.7078 56.5615 97.8305 56.5615 96.7547V70.2603C56.5615 69.1846 57.4389 68.3072 58.5146 68.3072Z"
          fill="#FE646F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M65.0027 68.3072C66.0785 68.3072 66.9559 69.1846 66.9559 70.2603V96.7547C66.9559 97.8305 66.0785 98.7078 65.0027 98.7078H58.5146C57.4389 98.7078 56.5615 97.8305 56.5615 96.7547V96.7545H63.0496C64.1254 96.7545 65.0027 95.8771 65.0027 94.8014V68.3072Z"
          fill="#FD4755"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.73437 63.0336H9.22246C10.2982 63.0336 11.1756 63.9109 11.1756 64.9865V96.7543C11.1756 97.8301 10.2982 98.7074 9.22246 98.7074H2.73437C1.65859 98.7074 0.78125 97.8301 0.78125 96.7543V64.9865C0.78125 63.9109 1.65859 63.0336 2.73437 63.0336Z"
          fill="#6CF5C2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.22246 63.0336C10.2982 63.0336 11.1756 63.9109 11.1756 64.9865V96.7543C11.1756 97.8301 10.2982 98.7074 9.22246 98.7074H2.73437C1.65859 98.7074 0.78125 97.8301 0.78125 96.7543V96.7541H7.26934C8.34512 96.7541 9.22246 95.8768 9.22246 94.801V63.0336Z"
          fill="#00E499"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.7861 53.9117H27.2742C28.35 53.9117 29.2273 54.7891 29.2273 55.8648V96.7543C29.2273 97.8301 28.35 98.7074 27.2742 98.7074H20.7861C19.7104 98.7074 18.833 97.8301 18.833 96.7543V55.8648C18.833 54.7891 19.7104 53.9117 20.7861 53.9117Z"
          fill="#8AC9FE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.2742 53.9117C28.35 53.9117 29.2273 54.7891 29.2273 55.8648V96.7543C29.2273 97.8301 28.35 98.7074 27.2742 98.7074H20.7861C19.7104 98.7074 18.833 97.8301 18.833 96.7543V96.7541H25.3211C26.3969 96.7541 27.2742 95.8768 27.2742 94.801V53.9117Z"
          fill="#60B7FF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M75.5275 69.5764C74.5518 70.9677 73.4047 72.2306 72.1133 73.3351L81.3969 82.4355L84.9313 78.7932L75.5275 69.5764Z"
          fill="#A79BA7"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M75.5906 69.5139C75.408 70.1127 75.0678 70.5678 74.5801 70.869L83.8023 80.0307L84.9318 78.7932L75.5906 69.5139Z"
          fill="#837683"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M93.153 94.2477L97.1416 90.137C97.8422 89.4151 97.8245 88.2514 97.1028 87.5508L86.0549 76.8303C85.333 76.1297 84.1692 76.1473 83.4688 76.869L79.4799 80.9797C78.7795 81.7014 78.7969 82.8655 79.519 83.5659L90.5668 94.2862C91.2887 94.987 92.4524 94.9698 93.153 94.2477Z"
          fill="#F1BA84"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M84.4209 76.8307L95.4687 87.5512C96.1904 88.2518 96.2082 89.4153 95.5078 90.1371L91.5191 94.2479C91.3758 94.3958 91.2083 94.5182 91.0238 94.61C91.7176 94.9547 92.5871 94.8319 93.1537 94.2479L97.1426 90.1371C97.8432 89.4153 97.8254 88.2518 97.1035 87.551L86.0557 76.8305C85.4857 76.2774 84.6404 76.1719 83.9648 76.5072C84.1326 76.5909 84.2865 76.7 84.4209 76.8307Z"
          fill="#E3A76F"
        />
        <path
          d="M74.6769 73.6741C82.5333 65.8175 82.5331 53.0795 74.6764 45.223C66.8197 37.3666 54.0817 37.3668 46.2253 45.2235C38.3689 53.0802 38.3691 65.8182 46.2258 73.6746C54.0825 81.5311 66.8205 81.5308 74.6769 73.6741Z"
          fill="#8AC9FE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M60.4521 39.3311C71.5629 39.3311 80.5701 48.3381 80.5701 59.449C80.5701 70.5598 71.5629 79.567 60.4521 79.567C60.1246 79.567 59.7992 79.5586 59.4756 79.543C70.1326 79.0336 78.617 70.2322 78.617 59.449C78.617 48.6656 70.1328 39.8645 59.4756 39.3551C59.8009 39.3392 60.1265 39.3312 60.4521 39.3311Z"
          fill="#60B7FF"
        />
        <path
          d="M70.666 69.6635C76.3074 64.0219 76.3073 54.8753 70.6657 49.2339C65.0241 43.5925 55.8775 43.5927 50.2361 49.2342C44.5947 54.8758 44.5949 64.0225 50.2365 69.6638C55.878 75.3052 65.0247 75.3051 70.666 69.6635Z"
          fill="#ECEFF1"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M60.4518 45.0029C60.7801 45.0029 61.1055 45.015 61.4283 45.0365C53.9057 45.5389 47.959 51.7988 47.959 59.4488C47.959 67.0986 53.9057 73.3588 61.4283 73.8611C61.1055 73.8826 60.7801 73.8947 60.4518 73.8947C52.4734 73.8947 46.0059 67.4272 46.0059 59.4488C46.0059 51.4707 52.4736 45.0029 60.4518 45.0029Z"
          fill="#D1D1D6"
        />
      </svg>
    </>
  )
}
