import { navigate } from 'gatsby'
import React, { useContext } from 'react'
import CTA from '../../components/CTA'
import Footer from '../../components/Footer'
import Hero from '../../components/Hero/Hero'
import Plan from '../../components/Plan'
import { FileSVG } from '../../images/svgs/FileSVG'
import { ResearcherSVG } from '../../images/svgs/ResearcherSVG'
import { AuthContext } from '../../pageContext'
import { QueryGraphql } from '../../utils/graphQlQuery'
import SurveyImage from '../../images/hero/surveys/survey_hero.png'
import './surveys.scss'

/* export const HeroData = {
  title: 'Looking to take your organization to the next level?',
  description: [
    {
      info: `Create simple and insightful surveys in a super fast way with just a few steps. We are always available to assist you whenever you need our help.`,
    },
  ],
  backgroundColor: {
    orange: 'bg-orange',
  },
} */

const Survey = () => {
  const { state, actions } = useContext(AuthContext)
  let data = QueryGraphql()
  const {
    allContentfulHero: { nodes: desc },
  } = data

  const mainData = desc
    .filter((val) => val.header === 'Akta Survey')
    .filter((val) => {
      const { desc: mainDesc } = val
      return mainDesc
    })

  console.log({ mainData })

  const results = mainData[0]

  const { header, mainDescription, title: info, backgroundColor } = results

  const fullDescription = mainDescription.map((val, ind) => {
    let description = {}
    description.info = val
    return description
  })

  const handleEntry = (type) => {
    if (state.isLoggedIn) {
      if (type === 'researcher') {
        navigate('/portal')
      } else if (type === 'respondent') {
        navigate('/portal')
      } else if (type === 'take') {
        navigate('/portal/take-a-survey')
      }
    } else {
      navigate(`/auth?sign-up?${type}`)
    }
  }

  return (
    <div className="surveys-container">
      {/* <Hero {...mainDesc} isButton={false} /> */}
      <Hero
        description={fullDescription}
        title={header}
        backgroundColor={`bg-orange`}
        image={SurveyImage}
      />
      <div className="survey-fluid-container">
        <div className="row w-70 jt-cn mx-auto mt-2 mb-2">
          <div className="col ">
            <div className="row jt-cn">
              <ResearcherSVG />
            </div>
            <Plan
              title={'Researcher'}
              features={{ free: true, take: true }}
              handleClick={handleEntry}
            />
          </div>{' '}
          <div className="col ml-7">
            <div className="row jt-cn">
              <FileSVG />
            </div>
            <Plan
              title={'Business'}
              features={{
                free: true,
                take: true,
                personal: true,
                create: true,
              }}
              handleClick={handleEntry}
            />
          </div>
        </div>
        <div className="row jt-cn mx-auto w-50">
          <CTA
            width="70%"
            height="40px"
            size="14px"
            className="submitButton planButton"
          // onClick={(e) => handleClick(title.toLowerCase())}
          >
            Coming Soon
          </CTA>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Survey
